export default () => {


    function getQueryString() {
        var key = false,
            res = {},
            itm = null;
        // get the query string without the ?
        var qs = location.search.substring(1);
        // check for the key as an argument
        if (arguments.length > 0 && arguments[0].length > 1)
            key = arguments[0];
        // make a regex pattern to grab key/value
        var pattern = /([^&=]+)=([^&]*)/g;
        // loop the items in the query string, either
        // find a match to the argument, or build an object
        // with key/value pairs
        while (itm = pattern.exec(qs)) {
            if (key !== false && decodeURIComponent(itm[1]) === key)
                return decodeURIComponent(itm[2]);
            else if (key === false)
                res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }

        return key === false ? res : null;
    }




    const button = `
            <button id="arbcb.closebutton" aria-label="Fjerne chatknapp" class="arbcb__removechat"><span class="arbcb__removechat-innertext">&#x2715;</span></button>
            <button id="arbcb.startbutton" aria-label="Spør vår chatbot" class="arbcb__startbutton">
                <span class="arbcb__button-text">Spør vår chatbot</span>
                
                <svg id="arbcb_svg" viewBox="0 0 152 152" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="green-buble" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="AB_logo_2" transform="translate(-0.000000, 0.000000)" fill-rule="nonzero">
                            <circle id="arbobj_1" fill="#FFFFFF" cx="75.91" cy="72.91" r="68.12"></circle>
                            
                            <path d="M84.69,31.01 C85.21,32.7 84.69,34.35 83.44,34.75 C83.0480157,34.8890454 82.6424275,34.9862525 82.23,35.04 C82.4671899,35.1203979 82.7116937,35.1773371 82.96,35.21 C84.3485072,35.4418323 85.7528417,34.8973583 86.6221846,33.790136 C87.4915275,32.6829137 87.6872572,31.1894958 87.1326032,29.8956422 C86.5779491,28.6017885 85.3613539,27.7138114 83.96,27.58 C82.5289082,27.3991965 81.111317,28.0029146 80.25,29.16 C80.6647067,28.9509733 81.1004983,28.7867134 81.55,28.67 C82.77,28.27 84.17,29.32 84.69,31.01 Z" id="arbobj_2" fill="#0D7168"></path>
                            <path d="M69.13,30.19 C67.8,30.26 66.27,31.19 64.41,33.03 C64.27,33.17 66.97,31.62 67.65,32.34 C68.1249406,32.8868413 68.8414024,33.1606722 69.56,33.07 C70.56,32.99 71.12,32.49 71.06,31.63 C70.9159868,30.7046124 70.058082,30.0645177 69.13,30.19 Z" id="arbobj_3" fill="#0D7168"></path>
                            <path d="M75.91,0 C33.9860646,0 0,33.9860646 0,75.91 L0,151.82 L75.91,151.82 C117.833935,151.82 151.819999,117.833935 151.819999,75.91 C151.819999,33.9860651 117.833935,0 75.91,0 L75.91,0 Z" id="arbobj_" fill="#0D7168">
                                <animate attributeName="fill" values="#0D7168" dur="10s" repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </button> 
            <button id="arbcb.reopen" aria-label="Spør vår chatbot" class="arbcb__reopenchat"><span class="" style="position:relative; top:2px;"><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="none" viewBox="0 0 24 24" focusable="false" role="img"><path fill="currentColor" fill-rule="evenodd" d="M4.75 5A.25.25 0 0 1 5 4.75h11a.25.25 0 0 1 .25.25v.5a.75.75 0 0 0 1.5 0V5A1.75 1.75 0 0 0 16 3.25H5A1.75 1.75 0 0 0 3.25 5v10a.75.75 0 0 0 1.085.67l1.502-.75a.75.75 0 0 0-.671-1.341l-.416.207zM9 8.75a.25.25 0 0 0-.25.25v8c0 .138.112.25.25.25h7a.75.75 0 0 1 .335.08l2.915 1.457V9a.25.25 0 0 0-.25-.25zM7.25 9c0-.966.784-1.75 1.75-1.75h10c.966 0 1.75.784 1.75 1.75v11a.75.75 0 0 1-1.085.67l-3.842-1.92H9A1.75 1.75 0 0 1 7.25 17zm3 4a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.75-.75m3.75-.75a.75.75 0 0 0 0 1.5h.01a.75.75 0 0 0 0-1.5zm2.25.75a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5H17a.75.75 0 0 1-.75-.75" clip-rule="evenodd"></path></svg>
</span></button>
        `;


    const chatbotContainer = document.createElement("section");
    chatbotContainer.id = "arbcb.chatbot";
    chatbotContainer.classList.add("arbcb__container");
    chatbotContainer.innerHTML = button;


    document.body.insertAdjacentElement('beforeend', chatbotContainer);


    var settings = {
        
    }
    
    if(window.sessionStorage.getItem("arbcb__keepConversationId")){
        settings.conversationId = window.sessionStorage.getItem("arbcb__keepConversationId");
    }
    

    var options = {
        chatPanel: {
            styling: {
                avatarUrl: "//dev.prokomcdn.no/richard/arbeidstilsynet/src/assets/images/AB_logo-symbol.svg",
                fontFamily: "\"Work sans\", Roboto, sans-serif",
                //panelBackgroundColor: "#11C1B0",
                primaryColor: "#0D7168",
                // pace: "normal",
                position: {
                    spacingBottom: 25,
                    spacingRight: 25
                },
                chatBubbles: {
                    vaBackgroundColor: "#D6F1EE",
                    userBackgroundColor: "#E5E5E5"
                }
            },
            settings : settings
        }
    };
    
    // Define hash
    const triggerHash = (window.location.hash.match("arbcb-startchatid") ? window.location.hash.split("arbcb-startchatid=")[1] : false);
    
    if(getQueryString("arbcb-startchatid")){
        options.chatPanel.settings.startTriggerActionId = getQueryString("arbcb-startchatid");
    }
    if(triggerHash){
        options.chatPanel.settings.startTriggerActionId = triggerHash;
    }
    
    var boost = window.boostInit("https://arbeidstilsynet.boost.ai", options);


    window.chatbotCallback = function(text) {
        boost.chatPanel.sendMessage(text);
    };

    document.getElementById("arbcb.startbutton").addEventListener("click", () => {

        boost.chatPanel.show();

    }, false);
    
    
    if (window.location.href.match("arbcb-start")) {
        boost.chatPanel.show();
    }
    
    if(getQueryString("arbcb-startchatid") || triggerHash){
        boost.chatPanel.show();
    }
    
    var arbcb = document.querySelectorAll('.arbcb-start');
    for (var i = 0; i < arbcb.length; i++) {
        arbcb[i].addEventListener('click', function(e) {

            e.preventDefault();
            
            boost.chatPanel.show();
            
        });
    }
    
    
    // open windown in keepconversation
    if(window.sessionStorage.getItem("arbcb__keepConversationId")){
        // boost.chatPanel.show();
    }
    
    // set keepconversation in sessionstorage
    boost.chatPanel.addEventListener("conversationIdChanged", function(data){
        var id = data.detail.conversationId;
        window.sessionStorage.setItem("arbcb__keepConversationId",id);
    });
    
    
    // when close, remove session
    boost.chatPanel.addEventListener('chatPanelClosed', function () {
        window.sessionStorage.removeItem("arbcb__keepConversationId");
    });
    
    
    window.addEventListener('hashchange', function() {

        if (window.location.href.match("arbcb-start")) {
            event.preventDefault();
            boost.chatPanel.show();
        }

    }, false);
    
    
    document.getElementById("arbcb.reopen").addEventListener("click", function() {
        
       
            event.preventDefault();
            boost.chatPanel.show();
        
    });
    
    
    
    document.getElementById("arbcb.closebutton").addEventListener("click", function() {
        
        const startbutton = document.getElementById("arbcb.startbutton");
        startbutton.classList.add("arbcb__startbutton-hide");
        const closebutton = document.getElementById("arbcb.closebutton");
        closebutton.classList.add("arbcb__startbutton-hide");
        const reopenbutton = document.getElementById("arbcb.reopen");
        reopenbutton.classList.add("arbcb__reopenchat-show");
        sessionStorage.setItem("smaler-chat-on-mobile", "true")
        
        
    });
    
    if(sessionStorage.getItem("smaler-chat-on-mobile") === "true"){
        
        const startbutton = document.getElementById("arbcb.startbutton");
        startbutton.classList.add("arbcb__startbutton-hide");
        const closebutton = document.getElementById("arbcb.closebutton");
        closebutton.classList.add("arbcb__startbutton-hide");
        const reopenbutton = document.getElementById("arbcb.reopen");
        reopenbutton.classList.add("arbcb__reopenchat-show");
    }

}


// window.chatbotCallback("msg");
