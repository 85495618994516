

import "./style/style.scss";
import "./scripts/chatpanel.js";
import start from "./scripts/runChat.js";

(function() {

    document.addEventListener('DOMContentLoaded', function () {
    
        start();
            
    }, false);

})();

